// // .env.dev
// const REACT_APP_API_URL='http://10.21.76.236:8081'
// // .env.test
// const REACT_APP_API_URL='http://10.21.76.236'
// .env.prod
// const REACT_APP_API_URL='http://47.106.212.102'
const REACT_APP_API_URL='https://chat2db.com'
export default REACT_APP_API_URL 


